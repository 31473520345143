import { Pagination } from '../interfaces/pagination'

export const DefaultMonthPagination: Pagination = {
  limit: 31,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
export const DefaultMainPagination: Pagination = {
  limit: 20,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
export const DefaultHalfMainPagination: Pagination = {
  limit: 10,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
export const DefaultDozenPagination: Pagination = {
  limit: 12,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
export const DefaultLocationsPagination: Pagination = {
  limit: 5,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}

export const DefaultDetailsPagination: Pagination = {
  limit: 3,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
export const DefaultSearchPagination: Pagination = {
  limit: 5,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
export const DefaultSinglePagination: Pagination = {
  limit: 1,
  count: 0,
  total: 0,
  page: 0,
}
export const DefaultZeroPagination: Pagination = {
  limit: 0,
  count: 0,
  total: 0,
  page: 0,
}
/**
 * Define a limit to avoid performance issues
 **/
export const UnlimitedPagination: Pagination = {
  limit: undefined as any,
  count: 0,
  total: 0,
  page: 0,
  page_count: undefined,
}
