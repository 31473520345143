import { Dispatch, useState } from 'react'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  ErrorResponse,
  ExploreApi,
  ExploreMembershipPackage,
  ExploreMembershipPackageList,
} from '@citruscamps/citrus-client'
import { DefaultMainPagination, UnlimitedPagination } from '../../../constants/pagination'
import { Pagination } from '../../../interfaces/pagination'
import { generateApiConfig } from '../../../utils/client-config'
import { generateItemKey, generateListKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'

export const DefaultMembershipPackageFetchProps: MembershipPackageFetchProps = {
  sort: 'price',
  order: 'ASC',
  filter: {},
}

export interface MembershipPackageFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: {
    id?: string[]
  }
}

interface IProps {
  programId?: string
  pagination?: Pagination
  fetchProps?: MembershipPackageFetchProps
  overwriteFilters?: boolean
  fetchAll?: boolean
  enabled?: boolean
}

interface FetchMembershipPackages {
  data: ExploreMembershipPackage[]
  error: ErrorResponse | null
  fetchProps: MembershipPackageFetchProps
  isError: boolean
  isLoading: boolean
  pagination: Pagination
  setFetchProps: (value?: MembershipPackageFetchProps) => void
  setPagination: Dispatch<Pagination>
}

export const useFetchMembershipPackages = ({
  programId,
  pagination: initialPagination = DefaultMainPagination,
  fetchProps: initialFetchProps = DefaultMembershipPackageFetchProps,
}: IProps): FetchMembershipPackages => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [fetchProps, setFetchProps] = useState<MembershipPackageFetchProps>(initialFetchProps)
  const queryKeys = generateListKey({
    type: 'membership_package',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { ...fetchProps.filter, program_id: programId },
  })
  const {
    isInitialLoading: isLoading,
    isError,
    data: resp,
    error,
  } = useQuery<ExploreMembershipPackageList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      const skip = pagination.limit ? pagination.page * pagination.limit : undefined
      const response = await requestHandler<ExploreMembershipPackageList>(() =>
        client.findMembershipPackageExploreSession(
          {
            program_id: programId,
            limit: pagination.limit,
            skip,
            order: fetchProps.order,
            sort: fetchProps.sort,
            id:
              fetchProps.filter.id && fetchProps.filter.id.length > 0
                ? fetchProps.filter.id
                : undefined,
          },
          { signal },
        ),
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'membership_package',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    {
      enabled: !!programId,
    },
  )

  const _setFetchProps = (val?: MembershipPackageFetchProps) => {
    setPagination(initialPagination)
    return setFetchProps(val || DefaultMembershipPackageFetchProps)
  }

  return {
    data: resp?.data || [],
    error,
    fetchProps,
    isError,
    isLoading,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    setFetchProps: _setFetchProps,
    setPagination,
  }
}

interface FetchInfiniteMembershipPackages {
  data: ExploreMembershipPackage[]
  error: ErrorResponse | undefined
  fetchProps: MembershipPackageFetchProps
  isError: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  isLoading: boolean
  hasNextPage: boolean
  pagination: Pagination
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ExploreMembershipPackageList, ErrorResponse>>
}

export const useFetchInfiniteMembershipPackages = ({
  programId,
  pagination = DefaultMainPagination,
  fetchAll = false,
  enabled = true,
  fetchProps = DefaultMembershipPackageFetchProps,
}: IProps): FetchInfiniteMembershipPackages => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const queryKeys = generateListKey({
    type: 'membership_package',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { ...fetchProps.filter, program_id: programId },
  })

  const {
    data: resp,
    error,
    isError,
    isInitialLoading: isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<ExploreMembershipPackageList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const response = await requestHandler<ExploreMembershipPackageList>(() =>
        client.findMembershipPackageExploreSession(
          {
            program_id: programId,
            limit: pg.limit,
            skip,
            order: fetchProps.order,
            sort: fetchProps.sort,
            id:
              fetchProps.filter?.id && fetchProps.filter?.id.length > 0
                ? fetchProps.filter.id
                : undefined,
          },
          { signal },
        ),
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'membership_package',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    {
      getNextPageParam: (resp: ExploreMembershipPackageList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: ExploreMembershipPackageList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled: !!programId && !!enabled,
    },
  )
  if (!(isFetching || isFetchingNextPage || isLoading) && hasNextPage && fetchAll) {
    fetchNextPage()
  }
  const response = [...(resp?.pages || [])].pop()

  return {
    data:
      (resp?.pages || []).reduce<ExploreMembershipPackage[]>(
        (list, p) => [...list, ...p.data],
        [],
      ) || [],
    error: error || undefined,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading,
    pagination: {
      ...pagination,
      count: response?.count || pagination.count,
      total: response?.total || pagination.total,
      page: response?.page || pagination.page,
      page_count: response?.page_count || pagination.page_count,
    },
    fetchNextPage,
  }
}
