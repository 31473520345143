import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'mapbox-gl/dist/mapbox-gl.css'
import App from 'next/app'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Cookies, CookiesProvider } from 'react-cookie'
import 'react-image-crop/dist/ReactCrop.css'
import 'react-phone-input-2/lib/bootstrap.css'
import 'react-phone-input-2/lib/style.css'
import { ProvideFlags } from '../components/FeatureFlag/hooks/useFlags'
import { ProvideAuth } from '../hooks/useAuth'
import { ProvideCitrusRouter } from '../hooks/useCitrusRouter'
import { ProvideProgram } from '../hooks/useFetchProgram'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { ProvideSupport } from '../hooks/useSupport'
import { ProvideToast } from '../hooks/useToast'
import '../styles/styles.scss'
import { getDehydratedStateTyped } from '../utils/getDehydratedStateTyped'

const CitrusApp = ({ pageProps, ...props }: any) => {
  const isBrowser = typeof window !== 'undefined'
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
          },
        },
      }),
  )
  const { query } = useRouter()
  useLocalStorage(
    `discount-code-${query.program_id}`,
    Array.isArray(query.discount) ? query.discount : [query.discount].filter(Boolean),
    true,
  )
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={getDehydratedStateTyped({ query, state: pageProps.dehydratedState })}>
        <CookiesProvider cookies={isBrowser ? undefined : new Cookies(pageProps.cookies)}>
          <ProvideCitrusRouter hostname={pageProps.hostname} pathname={pageProps.pathname}>
            <ProvideAuth>
              <ProvideProgram>
                <ProvideFlags>
                  <ProvideToast>
                    <ProvideSupport>
                      <App {...props} pageProps={pageProps} />
                    </ProvideSupport>
                  </ProvideToast>
                </ProvideFlags>
              </ProvideProgram>
            </ProvideAuth>
          </ProvideCitrusRouter>
        </CookiesProvider>
      </Hydrate>
      {typeof window !== 'undefined' && window.top === window && (
        <>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="bottom-left"
            closeButtonProps={{ style: { right: 'unset' } }}
          />
        </>
      )}
    </QueryClientProvider>
  )
}

export default CitrusApp
