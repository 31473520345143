export const ROUTES = {
  HOME: '/',
  SIGN_UP: '/pg/[program_id]/signup',
  LOGIN: '/pg/[program_id]/login',
  LOGOUT: '/pg/[program_id]/logout',
  FORGOT_PASSWORD: '/pg/[program_id]/forgot-password',
  RECOVER_PASSWORD: '/pg/[program_id]/recovery/[recovery_id]',
  CHANGE_PASSWORD: '/pg/[program_id]/profile/change-password',
  PROFILE_TAB: '/pg/[program_id]/profile/[tab_id]',
  PROFILE_EDIT: '/pg/[program_id]/profile/edit',
  PROFILE: '/pg/[program_id]/profile/account',
  FAMILY_DETAILS: '/pg/[program_id]/profile/family/[attendee_id]',
  MERGE_ATTENDEE: '/pg/[program_id]/profile/family/[attendee_id]/merge',
  FAMILY_ADD: '/pg/[program_id]/profile/family/add',
  FAMILY_EDIT_PERSONAL: '/pg/[program_id]/profile/family/[attendee_id]/edit-personal',
  FAMILY_EDIT_EMERGENCY: '/pg/[program_id]/profile/family/[attendee_id]/edit-emergency',
  FAMILY_CONNECT: '/pg/[program_id]/profile/family/[attendee_id]/connect',
  SCHEDULE: '/pg/[program_id]/profile/schedule',
  ORDERS: '/pg/[program_id]/profile/orders',
  ORDER_DETAILS: '/pg/[program_id]/profile/orders/[order_id]',
  REFUND_ORDER: '/pg/[program_id]/profile/orders/[order_id]/refund',
  PAYMENT_METHODS: '/pg/[program_id]/profile/payment-methods',
  MESSAGES: '/pg/[program_id]/profile/messages',
  MESSAGE_DETAIL: '/pg/[program_id]/profile/messages/[message_id]',
  PREFERENCES: '/pg/[program_id]/profile/preferences',
  ONLINE_EVENT: '/pg/[program_id]/online/[registration_id]',
  REFRESH_TOKEN: '/token',
  EXPLORE: '/pg/[program_id]',
  EXPLORE_EMBED: '/embed/[program_id]',
  EXPLORE_APPOINTMENTS: '/pg/[program_id]/schedule',
  APPOINTMENT_DETAILS: '/pg/[program_id]/schedule/[appointment_type_id]',
  PACKAGES: '/pg/[program_id]/packages',
  SESSION_PACKAGES: '/pg/[program_id]/packages/session',
  MEMBERSHIP_PACKAGES: '/pg/[program_id]/packages/membership',
  EVENT_DETAILS: '/pg/[program_id]/evt/[event_id]',
  EVENT_REGISTER: '/pg/[program_id]/evt/[event_id]/register',
  APPOINTMENT_REGISTER: '/pg/[program_id]/apt/[appointment_type_id]/register',
  CHECKOUT: '/pg/[program_id]/checkout/[order_id]',
  FEEDBACK: '/pg/[program_id]/feedback',
  PAYMENT_CONFIRM: '/confirm-payment',
  UNSUBSCRIBE: '/pg/[program_id]/unsubscribe',
}
